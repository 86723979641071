import { default as _404hJLphgzEFlMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/404.vue?macro=true";
import { default as _500XU2TdR65KLMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/500.vue?macro=true";
import { default as barcodeLocationPrintS51gp2KUZHMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/barcodeLocationPrint.vue?macro=true";
import { default as barcodeProductPrint1NWRsZEHtXMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/barcodeProductPrint.vue?macro=true";
import { default as chart8UHl56NytkMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/chart.vue?macro=true";
import { default as deliveryCancelModalYVswwkE6iKMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/deliveryCancelModal.vue?macro=true";
import { default as deliveryChangeModal9WLZaNnIl2Meta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/deliveryChangeModal.vue?macro=true";
import { default as deliveryProcessModalSSE61ovRP0Meta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/deliveryProcessModal.vue?macro=true";
import { default as individualCancelModalq5xW5bkZ76Meta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/individualCancelModal.vue?macro=true";
import { default as invoiceInvoiceModalNpNzpedLcyMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/invoiceInvoiceModal.vue?macro=true";
import { default as invoiceReceiptModalxVBGpe32fvMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/invoiceReceiptModal.vue?macro=true";
import { default as matchingDeleteModalE936ugvwONMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/matchingDeleteModal.vue?macro=true";
import { default as orderCancelModalST06BMiZqCMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/orderCancelModal.vue?macro=true";
import { default as orderDeleteModalaGRWR2kqQ8Meta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/orderDeleteModal.vue?macro=true";
import { default as orderReturnModalaZUZ4sqAwlMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/orderReturnModal.vue?macro=true";
import { default as packingExclusionModalf9d7aCeltjMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/packingExclusionModal.vue?macro=true";
import { default as packingProhibitionModalFi5I7r5wPsMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/packingProhibitionModal.vue?macro=true";
import { default as productAditModalNWV5xBJ1QCMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/productAditModal.vue?macro=true";
import { default as productExchangeModalLd0s6w5bxVMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/productExchangeModal.vue?macro=true";
import { default as refundModalJA9zUcXPg6Meta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/refundModal.vue?macro=true";
import { default as reserveHoldModalOw0eKfXFwFMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/reserveHoldModal.vue?macro=true";
import { default as reserveUnholdModalMCTTYrqNPvMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/reserveUnholdModal.vue?macro=true";
import { default as returnModalE5WgyI4xG1Meta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/returnModal.vue?macro=true";
import { default as togetherPackingModalD271sVBnHkMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/togetherPackingModal.vue?macro=true";
import { default as errorkwUelXN7rNMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/error.vue?macro=true";
import { default as indexMu5UvduOQIMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/index.vue?macro=true";
import { default as invoicePrintEoBOdVb2GJMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/invoicePrint.vue?macro=true";
import { default as loginQqrrea13zGMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/login.vue?macro=true";
import { default as operation3SW8U0hhnKMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/operation.vue?macro=true";
import { default as registrationKn44lR4ZPSMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/registration.vue?macro=true";
import { default as testcsotM1zCIUMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/test.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404hJLphgzEFlMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500XU2TdR65KLMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "barcodeLocationPrint",
    path: "/barcodeLocationPrint",
    meta: barcodeLocationPrintS51gp2KUZHMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/barcodeLocationPrint.vue").then(m => m.default || m)
  },
  {
    name: "barcodeProductPrint",
    path: "/barcodeProductPrint",
    meta: barcodeProductPrint1NWRsZEHtXMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/barcodeProductPrint.vue").then(m => m.default || m)
  },
  {
    name: "chart",
    path: "/chart",
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/chart.vue").then(m => m.default || m)
  },
  {
    name: "csr-deliveryCancelModal",
    path: "/csr/deliveryCancelModal",
    meta: deliveryCancelModalYVswwkE6iKMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/deliveryCancelModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-deliveryChangeModal",
    path: "/csr/deliveryChangeModal",
    meta: deliveryChangeModal9WLZaNnIl2Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/deliveryChangeModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-deliveryProcessModal",
    path: "/csr/deliveryProcessModal",
    meta: deliveryProcessModalSSE61ovRP0Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/deliveryProcessModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-individualCancelModal",
    path: "/csr/individualCancelModal",
    meta: individualCancelModalq5xW5bkZ76Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/individualCancelModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-invoiceInvoiceModal",
    path: "/csr/invoiceInvoiceModal",
    meta: invoiceInvoiceModalNpNzpedLcyMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/invoiceInvoiceModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-invoiceReceiptModal",
    path: "/csr/invoiceReceiptModal",
    meta: invoiceReceiptModalxVBGpe32fvMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/invoiceReceiptModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-matchingDeleteModal",
    path: "/csr/matchingDeleteModal",
    meta: matchingDeleteModalE936ugvwONMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/matchingDeleteModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-orderCancelModal",
    path: "/csr/orderCancelModal",
    meta: orderCancelModalST06BMiZqCMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/orderCancelModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-orderDeleteModal",
    path: "/csr/orderDeleteModal",
    meta: orderDeleteModalaGRWR2kqQ8Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/orderDeleteModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-orderReturnModal",
    path: "/csr/orderReturnModal",
    meta: orderReturnModalaZUZ4sqAwlMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/orderReturnModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-packingExclusionModal",
    path: "/csr/packingExclusionModal",
    meta: packingExclusionModalf9d7aCeltjMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/packingExclusionModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-packingProhibitionModal",
    path: "/csr/packingProhibitionModal",
    meta: packingProhibitionModalFi5I7r5wPsMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/packingProhibitionModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-productAditModal",
    path: "/csr/productAditModal",
    meta: productAditModalNWV5xBJ1QCMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/productAditModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-productExchangeModal",
    path: "/csr/productExchangeModal",
    meta: productExchangeModalLd0s6w5bxVMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/productExchangeModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-refundModal",
    path: "/csr/refundModal",
    meta: refundModalJA9zUcXPg6Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/refundModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-reserveHoldModal",
    path: "/csr/reserveHoldModal",
    meta: reserveHoldModalOw0eKfXFwFMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/reserveHoldModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-reserveUnholdModal",
    path: "/csr/reserveUnholdModal",
    meta: reserveUnholdModalMCTTYrqNPvMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/reserveUnholdModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-returnModal",
    path: "/csr/returnModal",
    meta: returnModalE5WgyI4xG1Meta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/returnModal.vue").then(m => m.default || m)
  },
  {
    name: "csr-togetherPackingModal",
    path: "/csr/togetherPackingModal",
    meta: togetherPackingModalD271sVBnHkMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/csr/togetherPackingModal.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: errorkwUelXN7rNMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexMu5UvduOQIMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoicePrint",
    path: "/invoicePrint",
    meta: invoicePrintEoBOdVb2GJMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/invoicePrint.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginQqrrea13zGMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "operation",
    path: "/operation",
    meta: operation3SW8U0hhnKMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/operation.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationKn44lR4ZPSMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/test.vue").then(m => m.default || m)
  }
]